/* Logout Page Section */
@import (reference) "theme/variables.less";
@import (reference) "libs/bootstrap.3.2.grid.less";
@import (reference) "libs/mixins.less";

/***************************************/
/* Pinky Template */
/***************************************/

.pinky-template {
    // Logout Page dependencies
    @import "flux/modules/contest-card.less";
    @import "flux/modules/comment-board.less";
    @import "flux/modules/data-list.less";
    @import "flux/layout/ContestCommentBoard.less";

    .main-content {
        background-color: @site-bg-color;
    }

    /***************************************/
    /* Logout Contest Hero */
    /***************************************/
    .logoutNav-inner,
    .logoutHero-inner,
    .topContest-inner,
    .ContestCommentBoard-inner,
    .getStarted-inner {
        .site-section-inner;
        overflow: hidden;
    }

    .logoutNav {
        padding: 26px 0 0;
    }

    .logoutHero {
        background-color: @septenary-color-xxxlight;

        &-column {
            .make-xs-column(6);
            margin-bottom: @spacing-xxxlarge;
            padding-left: 0;

            &:nth-child(2) {
                text-align: center;
            }

            .contest-brief {
                word-wrap: break-word;
            }

            &-header {
                margin: -2px 0 @spacing-xsmall;
            }
        }

        &-imageFrame {
            background: url("/static/css/images/contests/featured-work-frame.png") no-repeat;
            display: inline-block;
            height: 408px;
            margin: 0 auto 46px;
            width: 395px;
        }

        &-featuredImage {
            border: 1px solid @quaternary-color-xxxlight;
            display: inline-block;
            height: 335px;
            margin: 0 auto;
            overflow: hidden;
            position: relative;
            top: 40px;
            width: 335px;

            img {
                .vertical-align(@pos:relative);
                object-fit: cover;
                width: 100%;
            }
        }

        &-winnerLabel {
            left: 50%;
            margin: 0 -50%;
            position: absolute;
            width: 100%;

            &Text {
                .type(heading2);
                background: url("/static/css/images/contests/winner-label-sprite.png") 0 -68px repeat-x @septenary-color-xxxlight;
                display: inline-block;
                font-weight: bold;
                height: 34px;
                line-height: 1.2;
                padding: 2px @spacing-xxsmall;
                text-transform: uppercase;
            }

            &::before,
            &::after {
                background: url("/static/css/images/contests/winner-label-sprite.png") no-repeat @septenary-color-xxxlight;
                content: "";
                display: inline-block;
                height: 34px;
                vertical-align: top;
                width: 23px;
            }

            &::after {
                background-position: 0 -34px;
            }
        }

        &-subHeader {
            .type(heading3);
            font-weight: bold;
            margin: @spacing-large + @spacing-xxsmall 0 12px;
        }

        &-contestDetails {
            .list-unstyled;

            &--accented {
                border-bottom: 1px solid @quaternary-color-xxxlight;
                padding-bottom: @spacing-small + @spacing-xxsmall;
            }
        }

        &-contestItem {
            .type(ui);
            display: inline-block;
            margin-right: @spacing-small + @spacing-xxsmall;
        }

        &-recommendedSkills {
            list-style: "-";
            padding: 0;

            li {
                display: inline-block;

                &::before {
                    content: "\2013";
                    margin: 0 @spacing-xxsmall;
                }

                &:first-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }

        &-employerFeedback {
            border-top: 1px solid @quaternary-color-xxxlight;
            clear: both;
            margin: @spacing-large @spacing-xsmall 0;
            padding-top: @spacing-small - @spacing-xxsmall;
            text-align: center;
        }

        &-noEmployerFeedback {
            clear: both;
            margin: @spacing-large auto 0;
            max-width: 780px;
            text-align: center;
        }

        &-employerHeader {
            .type(heading2);
            border-bottom: 1px solid @border-color-light;
            display: block;
            font-weight: bold;
            margin: @spacing-mid auto @spacing-small + @spacing-xxsmall;
            max-width: 360px;
            padding-bottom: 20px;
        }

        &-employeeTestimony {
            .type(heading3);
            font-family: serif;
            font-style: italic;
            line-height: 1.5;
            margin-top: @spacing-xxsmall;
        }

        &-profileImage {
            .type(ui);

            img {
                border: 1px solid @border-color-light;
                border-radius: 4px;
                margin: 0 @spacing-small;
                vertical-align: middle;
            }
        }
    }

    /***************************************/
    /* Top Contests  */
    /***************************************/

    .topContest {
        background-color: @septenary-color-xxlight;
        padding: @spacing-xxlarge 0 @spacing-xlarge;
        text-align: center;

        &-cards {
            list-style: none;
            margin: @spacing-large 0 @spacing-small;
            overflow: hidden;
            padding: 0;
        }

        &-header {
            .type(heading2);
            margin-bottom: @spacing-large + @spacing-xxsmall;
        }

        &-inner a {
            margin-bottom: 2px;
        }

        &-username {
            .truncate();
        }
    }

    .cardList {
        list-style: none;
        margin: 0 -10px;
        padding: 0;

        &-item {
            .make-xs-column(3);
            display: inline-block;
            float: none;
            margin: 0 auto @spacing-mid;
            width: 290px;

            &.show {
                display: inline-block;
            }
        }
    }

    .contestCard-cardLink {
        .type(ui);
        height: 207px; // custom height of cards
    }

    /***************************************/
    /* Get Started */
    /***************************************/

    .getStarted {
        margin: @spacing-xxxlarge 0 @spacing-xxlarge;
        text-align: center;

        &-list {
            .list-unstyled(@padding: 0 0 7px);
        }

        &-step {
            .make-xs-column(4);
            margin: 0 auto;
        }

        &-header {
            .type(heading2);
            font-weight: bold;
            margin-bottom: @spacing-xsmall + @spacing-xxsmall;
            text-align: center;
        }

        &-description {
            .type(ui);
            font-weight: bold;
            margin-top: @spacing-xxsmall;
            text-align: center;
            text-transform: uppercase;
        }

        &-subDescription {
            .type(ui);
            bottom: -4px;
            display: block;
            font-weight: normal;
            position: relative;
            text-transform: none;
        }

        &-button {
            margin: 0 auto;
            min-width: 320px;
            text-align: center;
        }

        &-joinLink {
            .type(landing);
            display: block;
            font-weight: bold;
            margin: @spacing-small 0;
        }
    }

    /***************************************/
    /* Responsive Stuff */
    /***************************************/

    .is-mobile-first & {
        @media (max-width: 781px) {
            .logoutHero {
                &-imageFrame {
                    display: none;

                    &-mobile {
                        background-position: center;
                        display: block !important;
                        margin: @spacing-xlarge auto;
                        text-align: center;
                        width: 95%;
                    }
                }

                &-column {
                    width: 100%;
                }
            }
        }

        @media (max-width: 780px) {
            .logoutHero {
                &-wrapper {
                    position: relative;
                }

                &-contestItem {
                    display: block;
                    margin: @spacing-xxsmall 0;
                }

                &-frame {
                    display: block !important;
                    margin-top: @spacing-large;
                    position: relative;
                    text-align: center;
                    width: 100%;
                }

                &-frameImage {
                    height: auto;
                    width: 100%;
                }

                &-noWinnersImage {
                    height: auto;
                    left: 0;
                    padding: 0 10%;
                    position: absolute;
                    top: 12%;
                    width: 100%;
                }

                &-imageLink {
                    .vertical-align(@pos:absolute);
                    display: block;
                    position: absolute;
                    width: 100%;
                }

                &-winnerImage {
                    max-height: 320px;
                    max-width: 100%;
                    padding: 0 10%;
                    width: auto;
                }

                &-employerFeedback {
                    background-color: @septenary-color-xxxlight;
                    margin: 0 -@spacing-mid;
                    padding: @spacing-mid;
                }

                &-noEmployerFeedback {
                    margin: 0 -@spacing-mid;
                }

                &-employerHeader {
                    margin: @spacing-mid auto;
                }

                &-profileImage {
                    word-wrap: break-word;

                    img {
                        margin: 0 @spacing-small 0 0;
                    }
                }
            }

            .getStarted-step {
                width: 100%;

                img {
                    width: 100%;
                }
            }

            .getStarted-button {
                min-width: 100%;
            }
        }

        @media (max-width: 480px) {
            .logoutHero {
                &-column {
                    padding: 0;
                }

                &-btnPostContest {
                    width: 100%;
                }
            }
        }
    }
}
