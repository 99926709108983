/*********************************************/
/* Contest Comment Board                     */
/* Currently used in contest page            */
/*********************************************/

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.ContestCommentBoard {
    background-color: @septenary-color-xxlight;
    padding-bottom: @spacing-xlarge;

    &-inner {
        &::before {
            background-color: @quaternary-color-xxlight;
            content: "";
            display: inline-block;
            height: 1px;
            margin-bottom: @spacing-xxxlarge;
            width: 100%;
        }
    }

    &-container {
        .card();
        align-self: flex-start;
        background-color: @septenary-color-xxxlight;
        border-radius: @border-radius;
        float: left;
        margin-bottom: @spacing-small;
        width: 66.8%;

        .no-message {
            display: inline-block;
            padding: 0 @spacing-small;
        }

        &--wide {
            width: 100%;
        }
    }

    &-header {
        .type(heading3);
        border-bottom: 1px solid @border-color-light;
        font-weight: bold;
        margin-bottom: 0;
        padding: @spacing-small;
    }

    &-showComments,
    &-loading {
        text-align: center;
    }

    &-loading {
        padding: 0 @spacing-small + @spacing-xxsmall @spacing-small + @spacing-xxsmall;
    }

    .commentBoard {
        margin: @spacing-large @spacing-xlarge @spacing-large @spacing-xxlarge + @spacing-xxsmall;
        padding: 0;
    }

    &-dataLists {
        display: flex;
        flex-direction: column;

        .dataList-set {
            .card();
            margin-left: 20px;
            width: 95%;
        }
    }

    &-dataList {
        width: 30%;
    }

    &-comment {
        background-color: @bg-color;
        padding: 40px;
    }

    &--MessageBoard {
        .commentBoard {
            &-comment {
                padding: 0 @spacing-small + @spacing-xxsmall @spacing-large @spacing-xlarge;
            }

            &-subList {
                .commentBoard-comment {
                    padding: 0 0 @spacing-large @spacing-xlarge;
                }
            }
        }
    }

    .no-message {
        padding: @spacing-small + @spacing-xxsmall;
    }
}

/***************************************/
/* Responsive Stuff */
/***************************************/

.is-mobile-first & {
    @media (max-width: @breakpoint-desktop-small) {
        .ContestCommentBoard {
            &-container {
                float: none;
                width: 100%;
            }

            .dataList-set {
                margin-left: 0;
                width: auto;
            }
        }
    }

    @media (max-width: 780px) {
        .ContestCommentBoard {
            &-inner::before {
                margin-bottom: @spacing-large;
            }

            &-comment {
                margin-top: @spacing-xsmall;
                width: 100%;
            }
        }
    }
}
